import React from "react";
import { PageErrorMarkerForPuppyService } from "../PageReadyMarkerForPuppyService";


interface ErrorState {
  hasError: boolean;
}
export class ErrorBoundary extends React.Component {
  state: ErrorState;

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {

      return <>
        <PageErrorMarkerForPuppyService />
        <h1>Error encountered while rendering</h1>
      </>
    }

    return this.props.children;
  }
}