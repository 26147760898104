import { BrowserSpaRoute } from "@wranggle/constants-iso";
import React from 'react';
import { Route, Routes } from "react-router-dom";
import './App.css';
import { RouteNotFound } from "./components/layout/RouteNotFound";
import { OrgExpiringPage } from "./components/OrgExpiringPage";
import { RenderForAnimation } from "./components/RenderForAnimation";
import { RenderForScreenshot } from "./components/RenderForScreenshot";


const AppRoutePathSegment = {
  OrgExpiringPage_1: BrowserSpaRoute.Landing_SyncOrgExpiringPage, // /pages/:backendCode/org/:organizationUid/sync/:pageDefUid/view/:viewType
  RenderForScreenshot_1: '/render-screenshot-1',
  RenderForAnimation_1: '/render-animation-1',
}


function App() {
  return (
    <Routes>
      <Route
        path={ AppRoutePathSegment.OrgExpiringPage_1 }
        element={ <OrgExpiringPage /> }
      />

      <Route
        path={ `${ AppRoutePathSegment.RenderForScreenshot_1 }/:screenshotCode`}
        element={ <RenderForScreenshot /> }
      />

      <Route
        path={ `${ AppRoutePathSegment.RenderForAnimation_1 }/:animationCode`}
        element={ <RenderForAnimation /> }
      />

      <Route
        path="*"
        element={ <RouteNotFound /> }
      />
    </Routes>
  );
}

export function screenshotPath(screenshotCode: string): string {
  return `${ AppRoutePathSegment.RenderForScreenshot_1 }/${screenshotCode}`;
}

export default App;
