import React  from "react";
import { useDataBucketSync } from "@wranggle/ws-client-sync-react";


export function RawDataView() {
  const { dataBucketStateByVarName, connection } = useDataBucketSync();
  (window as any)['ztmp'] = dataBucketStateByVarName; // tmp - rm!
  const { hasWsConnection } = connection;
  const varNames = Object.keys(dataBucketStateByVarName).sort();

  const content = varNames.map((varName, ndx) => {

    return (<div
      className="data-bucket"
      style={{ margin: '0 0 0 1.5rem' }}
      key={ ndx }
    >
      <h3>{ varName }</h3>
      <pre style={{ width: '95vw' }}>
      { JSON.stringify(dataBucketStateByVarName[varName], null, 2) }
    </pre>
    </div>)
  });


  return (
    <div className="RawDataView">
      { !hasWsConnection &&
      <p style={{ position: 'absolute', top: 5, right: 5, backgroundColor: '#aaa', color: '#333', padding: '1rem', border: '1px solid #000' }}>Disconnected!
      </p> }
      <h2>Raw Data View</h2>
      { content }
    </div>
  );
}