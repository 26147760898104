import { ObjectInspector } from 'react-inspector';
import { useDataBucketSync } from "@wranggle/ws-client-sync-react";
import { RuntimeProgressSync_dataType, TaskIdentification, RelayedLogEntry } from "@wranggle/constants-iso";
import "./Placeholder_RuntimeProgress.scss";


export function Placeholder_RuntimeProgress() {
  const { dataBucketStateByVarName, connection } = useDataBucketSync();
  const { hasWsConnection } = connection;

  const logEntryBucket = dataBucketStateByVarName[RuntimeProgressSync_dataType.logEntry]
  // const taskCycleBucket = dataBucketStateByVarName[RuntimeProgressSync_dataType.taskCycle]
  
  const logEntryKeys = Object.keys(logEntryBucket || {}).sort();

  const content = logEntryKeys.map((logEntryKey, ndx) => {
    const logEntry = logEntryBucket[logEntryKey];
    const { logLevel, data, message } = logEntry as RelayedLogEntry;
    const taskIdent = logEntry.taskIdent as TaskIdentification | undefined;


    return <div
      className={ `log-entry log-level-${ logLevel } ndx-${ ndx % 2 === 0 ? 'even' : 'odd' }` }
      key={ndx}
    >
      <p>{ message }</p>
      <pre style={{ width: '95vw' }}>
      { JSON.stringify(data || {}, null, 2) }
      </pre>
      {taskIdent &&
      <ObjectInspector
        name={ taskIdent.taskSid }
        data={ taskIdent }
        expandLevel={1}
      />
      }
    </div>
  });

  return (
    <div className="Placeholder_RuntimeProgress">
      { !hasWsConnection &&
      <p style={{ position: 'absolute', top: 5, right: 5, backgroundColor: '#aaa', color: '#333', padding: '1rem', border: '1px solid #000' }}>Disconnected!
      </p> }
      <h2>Placeholder_RuntimeProgress</h2>
      { content }
    </div>
  );
}


