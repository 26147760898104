import React from "react";
import {useSearchParam} from 'react-use';
import { decodePayloadFromQsParam } from "@wranggle/shared-browser-utils";


/**
 * Uses payload data in the location.search portion of the URL that was encoded using the `encodePayloadForQsParam` utility
 *  in @wranggle/shared-browser-utils.
 *
 * (That encodePayloadForQsParam util encodes a data object by JSON-stringifying, applying btoa (binary-to-ascii encoding) and then encodeURIComponent.)
 *
 * @param attrib Param name in the URL. 
 */
export function useEncodedQsPayload(attrib: string): Record<string, any> | undefined {
  const raw = useSearchParam(attrib);
  const [ payloadData, setPayloadData ] = React.useState<Record<string, any> | undefined>();
  React.useEffect(() => {
    if (raw) {
      setPayloadData(decodePayloadFromQsParam(raw));
    }
  }, [ raw, attrib ]);
  return payloadData;
}