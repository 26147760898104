import React from 'react';

interface RouteNotFoundProps extends React.PropsWithChildren<any> {
}

export function RouteNotFound(props: RouteNotFoundProps) {
  return (
    <div>Not Found</div>
  );
}
