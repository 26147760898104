import { ErrorRecord } from "@wranggle/constants-iso";
import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import { PageRenderErrorFunctionName } from "../page-constants";
import { useEncodedQsPayload } from "../state/use-encoded-qs-payload";
import { SpringjsTemplate, SpringTemplateAnimationOpts, } from "./animation-features/SpringjsTemplate";
import { ErrorBoundary } from "./layout/ErrorBoundary";
// import { RouteNotFound } from "./layout/RouteNotFound";
import { PageFinishedMarkerForPuppyService, PageReadyMarkerForPuppyService, } from "./PageReadyMarkerForPuppyService";
import "./RenderForAnimation.scss";


export const TimesnapAnimation_containerCssId = 'TimesnapAnimationContainer';  // must correspond with projects/puppy-snap/src/constants.ts. todo: refactor to some shared location
export const TimesnapAnimation_stopFunctionName = 'stopTimesnapRecording';
export const Timesnap_isForTimesnapFunctionName = 'isForTimesnap';
export const TimesnapAnimation_beginAnimationJs_dataAttribName = 'beginAnimationForTimesnapRecording';


export enum AnimationFeatureCode {
  SpringjsTemplate = 'springjs-template',
  // perhaps add other animation libs, like anime.js
}

const DefaultScreenshotContainerCss = {
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
}
export function RenderForAnimation() {
  const { animationCode } = useParams() as Record<string, string>;
  const animationQsData = useEncodedQsPayload('animationPageData') as SpringTemplateAnimationOpts | undefined;
  const genericMehQsData = useEncodedQsPayload('payload') as SpringTemplateAnimationOpts | undefined;
  const containerCss = Object.assign({}, DefaultScreenshotContainerCss, useEncodedQsPayload('containerCss'));

  const animationOpts = animationQsData || genericMehQsData || {} as SpringTemplateAnimationOpts;

  const [ shouldStartAnimation, setShouldStartAnimation ] = React.useState<boolean>(false);
  const [ isAnimationFinished, setIsAnimationFinished ] = React.useState<boolean>(false);

  const markAnimationFinished = React.useCallback(() => {
    if (!isAnimationFinished) {
      console.log('Animation javascript says it has finished');
      setIsAnimationFinished(true);
      if (_isRunningInPuppeteerForTimesnap()) {
        const stopRecording = (window as any)[TimesnapAnimation_stopFunctionName];
        stopRecording();
      }
    }
  }, [ isAnimationFinished ]);

  const invalidPageData = React.useCallback((trouble?: Partial<ErrorRecord>) => {
    const puppySnapValidationErrorCallback = (window as any)[PageRenderErrorFunctionName];
    if (typeof puppySnapValidationErrorCallback === 'function') {
      puppySnapValidationErrorCallback({
        errorCode: 'InvalidPageData',
        ...(trouble || {}),
      });
    } else {
      console.error('InvalidPageData', trouble);
    }
    markAnimationFinished();
  }, [ markAnimationFinished ]);

  React.useEffect(() => {
    if (!_isValidAnimationCode(animationCode)) {
      console.error(`Animation not found! animationCode "${animationCode}"`); // or throw?
      markAnimationFinished();
      // todo: PageRenderErrorFunctionName
    } else {
      const body = document.querySelector('body');
      body && body.setAttribute('class', 'PageForRecordingAnimation');

      if (_isRunningInPuppeteerForTimesnap()) {
        window.addEventListener("message", (evt) => {
          const data = JSON.parse(evt.data);
          console.log('Timesnap script is signalling it is ready for animation to start');
          if (data && data[TimesnapAnimation_beginAnimationJs_dataAttribName]) {
            setShouldStartAnimation(true);
          }
        });
      } else {
        setShouldStartAnimation(true);
      }
    }
  }, [ animationCode, markAnimationFinished ]);


  let content;
  if (shouldStartAnimation) {
    content = <SpringjsTemplate
      markAnimationFinished={ markAnimationFinished }
      invalidPageData={ invalidPageData }
      animationOpts={ animationOpts }
    />;
  } else {
    content = <></>;
  }

  return <div
    id={ TimesnapAnimation_containerCssId }
    style={ containerCss || {} }
  >
    <PageReadyMarkerForPuppyService />

    { isAnimationFinished && <PageFinishedMarkerForPuppyService /> }
    <Suspense fallback={ <div>loading...</div>  }>
      <ErrorBoundary>
        { content }
      </ErrorBoundary>
    </Suspense>
  </div>
}


/**
 * Returns true if running under for timestamp in puppeteer. False if not, eg, when trying things out in browser.
 * note: determines timesnap present by detecting if the stopRecording function is present on window, which is added
 * by Timesnap using puppeteer's `page.exposeFunction` because we're providing its "stopFunctionName" option.
 * See fanggle-microservices/projects/puppy-snap/app/src/browser-scripts/record-timesnap-js-animation.ts
 *
 */
function _isRunningInPuppeteerForTimesnap(): boolean {
  const isForTimesnap = (window as any)[ Timesnap_isForTimesnapFunctionName ];
  return typeof isForTimesnap === 'function' && isForTimesnap();
}


function _isValidAnimationCode(animationCode: AnimationFeatureCode | string): boolean {
  return animationCode === AnimationFeatureCode.SpringjsTemplate;
}