import { BrowserSpaRoute } from "@wranggle/constants-iso";
import { OrgExpiringPageProvider } from "@wranggle/ws-client-sync-react";
import React from "react";
import { useParams } from "react-router-dom";
import { bestWranggleApiBaseUrl } from "../api/wranggle-base-url";
import { Placeholder_RuntimeProgress } from "./org-expiring-page-views/Placeholder_RuntimeProgress";
import { RawDataView } from "./org-expiring-page-views/RawDataView";

window['process'] = window['process'] || { env: {} }; // some todos around this for shared-utils-iso


export function OrgExpiringPage() {
  const [ apiBaseUrl, setApiBaseUrl ] = React.useState<string | undefined>();

  React.useEffect(() => {
    const url = bestWranggleApiBaseUrl({ routeDef: BrowserSpaRoute.Landing_SyncOrgExpiringPage })
    console.log('Pointing page to wranggle backend:', url);
    setApiBaseUrl(url);
  }, []);

  const { viewType } = useParams() as any;
  console.log('tmp - OrgExpiringPage', { apiBaseUrl, viewType });

  if (!apiBaseUrl) {
    return <></>; // loading indicator?
  }
  let content;
  if (viewType === 'raw') {
    content = <RawDataView/>;
  } else if (viewType === 'runtime_progress') {
    content = <Placeholder_RuntimeProgress />;
  } else {
    content = <p>unknown viewType { viewType }</p>;
  }

  return <OrgExpiringPageProvider
    apiBaseUrl={ apiBaseUrl }
  >
    <p style={{ position: 'absolute', top: 5, right: 5 }}>apiBaseUrl { apiBaseUrl }</p>

    { content }
  </OrgExpiringPageProvider>

}

