import React from "react";
import { NameTitleLogo_SpringHerdedLetters_1 } from "./name-title-logo-support/NameTitleLogo_SpringHerdedLetters_1";
import type  { NameTitleTemplate_herdedLetters_1_props } from "./name-title-logo-support/NameTitleLogo_SpringHerdedLetters_1";
import { ErrorRecord } from "@wranggle/constants-iso";



export enum AnimationMethod {
  NameTitleLogo_herdedLetters_1 = 'NameTitleLogo_herdedLetters_1',
  // NameTitleSvgLogo_1 = 'NameTitleSvgLogo_1',
}

// export type NameTitleLogoProps = NameTitleLogoProps_Svg_1 | NameTitleLogoProps_Simple_1;
export type SpringTemplateAnimationOpts = NameTitleTemplate_herdedLetters_1_props;
export interface SpringTemplateProps {
  markAnimationFinished: () => void;
  invalidPageData: (trouble?: Partial<ErrorRecord>) => void;
  animationOpts: SpringTemplateAnimationOpts;
}


/**
 * Templates using the [react-spring](https://react-spring.io/) lib for spring-physics animations.
 *
 * For config settings, see: https://react-spring-visualizer.com/
 * Docs: https://react-spring.io/hooks/use-spring
 *
 * @param props
 * @constructor
 */
export function SpringjsTemplate(props: SpringTemplateProps) {
  // @ts-ignore
  window['ztmpDel'] = props; // tmp - rm
  const animationMethod = (props.animationOpts || {}).animationMethod;

  if (animationMethod === AnimationMethod.NameTitleLogo_herdedLetters_1) {
    return <NameTitleLogo_SpringHerdedLetters_1 { ...props } />
  } else {
    return <UnknownJsTemplate { ...props } />
  }
}


function UnknownJsTemplate({ animationOpts, markAnimationFinished }: SpringTemplateProps) {
  const animationMethod = (animationOpts || {}).animationMethod;

  React.useEffect(() => {
    console.error('Animation method missing', { animationOpts })
    markAnimationFinished();
  }, [ animationOpts, markAnimationFinished ]);

  const animationMethodBlurb = animationMethod ? `View for "${ animationMethod }" does not exist` : '"animationMethod" view type is a required field';

  return <div>ERROR: animationMethod { animationMethodBlurb }</div>
}