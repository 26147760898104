import React, { CSSProperties } from "react";
import { animated, config as springConfig, SpringConfig, useSpring } from "react-spring";
import { PageErrorMarkerForPuppyService } from "../../PageReadyMarkerForPuppyService";
import type { SpringTemplateProps } from "../SpringjsTemplate";
import { AnimationMethod } from "../SpringjsTemplate";


/**
 * Will decide how to refactor this stuff after there are multiple animations for title-page
 */
interface NameTitleAnimationBaseProps {
  animationMethod: AnimationMethod;
}

export interface NameTitleTemplate_herdedLetters_1_props extends NameTitleAnimationBaseProps {
  /**
   * Primary line of text is split into individual letters
   */
  primaryLine: string;

  /**
   * Secondary line of text is not split, but animated as a single entity. (Though text-related css animation might look
   *  otherwise, such as the letter-spacing default animation effect.)
   */
  secondaryLine: string;

  primaryLineCss?: CSSProperties;
  primaryLetterCss?: CSSProperties;
  primaryLetterCss_extraSpringFrom?: Record<string, any>;
  primaryLetterCss_extraSpringTo?: Record<string, any>;
  primaryLetter_extraSpringConfig?: Partial<SpringConfig>;

  secondaryLineCss?: CSSProperties;
  secondaryLineCss_extraSpringFrom?: Record<string, any>;
  secondaryLineCss_extraSpringTo?: Record<string, any>;
  secondaryLine_extraSpringConfig?: Partial<SpringConfig>;

  textContainerCss?: CSSProperties;
}


const DefaultTextContainerCss = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  left: '6vw',
  bottom: '2vh',
  height: '30vh',
  overflow: 'visible',
}
const DefaultTextCss = {
  whiteSpace: 'nowrap',
  fontFamily: '"Source Sans Pro", "Open Sans", Roboto, Arial, Helvetica, sans-serif',
}

const DefaultPrimaryLineCss = Object.assign({}, DefaultTextCss, {
  // flexGrow: 1,
  // marginTop: '10vh',
  whiteSpace: 'nowrap',
  overflow: 'visible',
});

const DefaultPrimaryLetterCss = Object.assign({}, DefaultTextCss, {
  fontSize: '10vh',
  color: '#fff',
  textShadow: '1.5vh 1.5vh 1.5vh #6c6c6c',
  stroke: '#333',
  strokeWidth: '2px',
  background: 'none',
  display: 'inline-block',
}) as CSSProperties;

const DefaultSecondaryLineCss = Object.assign({}, DefaultTextCss, {
  // flexGrow: 1,
  // alignSelf: 'center',
  fontSize: '4vh',
  color: '#fff',
  lineHeight: 2.0,
  fontWeight: 'bold',
  textShadow: '1.5vh 1.5vh 1.5vh #6c6c6c',
  stroke: '#333',
  strokeWidth: '3px',
  background: 'none',
  whiteSpace: 'nowrap',
  overflow: 'visible',
});

const DefaultPrimaryLetterSpringjsConfig = {
  ...springConfig.wobbly,
  friction: springConfig.wobbly.friction * 1.2, //  * 1.7,
  mass: 1.7,
  precision: .05,
  velocity: -.03,
}

const DefaultSecondaryLineSpringjsConfig = {
  ...springConfig.molasses,
  precision: .08,
}


const regexIsSpace = /\s/;
export function NameTitleLogo_SpringHerdedLetters_1({ animationOpts, markAnimationFinished, invalidPageData }: SpringTemplateProps) {
  let { primaryLine, secondaryLine, textContainerCss,
    primaryLineCss, primaryLetterCss, primaryLetterCss_extraSpringFrom, primaryLetterCss_extraSpringTo, primaryLetter_extraSpringConfig,
    secondaryLineCss, secondaryLineCss_extraSpringFrom, secondaryLineCss_extraSpringTo, secondaryLine_extraSpringConfig } = animationOpts as NameTitleTemplate_herdedLetters_1_props;

  const [ hasInvalidOpts, setHasInvalidOpts ] = React.useState(false);
  const [ lettersFinishedCount, setLettersFinishedCount ] = React.useState(0);
  const [ secondaryFinished, setSecondaryFinished ] = React.useState(!secondaryLine);

  React.useEffect(() => {
    if (!primaryLine && !secondaryLine) {
      invalidPageData({
        reason: 'primaryLine and secondaryLine cannot both be empty',
      });

      setHasInvalidOpts(true);
    } else {
      console.log('rendering NameTitleLogo_SpringHerdedLetters_1', { primaryLine, secondaryLine });
    }
  }, [ invalidPageData, primaryLine, secondaryLine ]);
  
  const letters = (primaryLine || '').split('');

  const incrementLettersFinished = () => setLettersFinishedCount((count: number) => {
    return count + 1;
  })

  let herdedLetterCount = 0;
  const primaryLetters = letters.length && letters.map((letter: string, ndx: number) => {
    const letterCss = Object.assign({}, DefaultPrimaryLetterCss, primaryLetterCss);
    const letterClassName = 'primaryLetter';

    if (regexIsSpace.test(letter)) {
      return <span
        key={ndx}
        className={ letterClassName }
        style={{ ...letterCss, ...(primaryLetterCss_extraSpringTo || {}) }}
      >&nbsp;</span>;
    } else {
      herdedLetterCount += 1;
      return <HerdedLetter
        letter={letter}
        key={ndx}
        ndx={ndx}
        herdedLetterCount={herdedLetterCount}
        onRest={ () => incrementLettersFinished() }
        letterCss={ letterCss }
        letterClassName={ letterClassName }
        letterCss_extraSpringFrom={ primaryLetterCss_extraSpringFrom }
        letterCss_extraSpringTo={ primaryLetterCss_extraSpringTo }
        primaryLetter_extraSpringConfig={ primaryLetter_extraSpringConfig }
      />
    }
  });


  const secondaryLineSpring = useSpring({
    from: {
      x: window.innerWidth,
      letterSpacing: '15vw',
      ...(secondaryLineCss_extraSpringFrom || {}),
    },
    to: {
      x: 0,
      letterSpacing: '0vw', // chokes on 'normal',
      ...(secondaryLineCss_extraSpringTo || {}),
    },
    config: {
      ...DefaultSecondaryLineSpringjsConfig,
      ...(secondaryLine_extraSpringConfig || {})
    },
    onRest: () => {
      console.log('Animation of secondary line finished');
      setSecondaryFinished(true);
    }
  });

  React.useEffect(() => {
    if (secondaryFinished && lettersFinishedCount >= herdedLetterCount) {
      console.log('Animation finished!');
      markAnimationFinished();
    }
  }, [ lettersFinishedCount, secondaryFinished, herdedLetterCount, markAnimationFinished ]);

  if (hasInvalidOpts) {
    return <PageErrorMarkerForPuppyService />;
  }
  return <div className="NameTitleLogoProps_herdedLetters_1">
    <div
      className="text-container"
      style={ Object.assign({}, DefaultTextContainerCss, textContainerCss) }
    >
      <div
        className="primaryLine"
        style={ Object.assign({}, DefaultPrimaryLineCss, primaryLineCss)}
      >
        { primaryLetters }
      </div>

      <animated.div
        className="secondaryLine"
        style={ Object.assign({}, DefaultSecondaryLineCss, secondaryLineCss, secondaryLineSpring ) }
      >{ secondaryLine }</animated.div>

    </div>
  </div>
}


interface HerdedLetterProps {
  letter: string;
  ndx: number;
  herdedLetterCount: number;
  onRest: () => void;

  letterCss?: CSSProperties;
  letterClassName?: string;
  letterCss_extraSpringFrom?: Record<string, any>;
  letterCss_extraSpringTo?: Record<string, any>;
  primaryLetter_extraSpringConfig?: Partial<SpringConfig>;

  primaryLetter_startOffsetX?: number;
  primaryLetter_startOffsetY?: number;
}
function HerdedLetter(props: HerdedLetterProps) {
  const { letter, ndx, herdedLetterCount, onRest, letterCss, letterClassName, letterCss_extraSpringFrom, letterCss_extraSpringTo, primaryLetter_extraSpringConfig } = props;
  const zIndex = herdedLetterCount - ndx - 1;
  const startAngle = Math.floor((180 * ndx / herdedLetterCount)); // - 90;
  const delay = (1000 * ndx / herdedLetterCount);

  // transform: `translate3d(-${Math.floor(window.innerWidth)}px,70vh,0) rotate(${ startAngle }deg)`,
  const springCss = useSpring({
    from: {
      transform: `translate3d(-75vw,75vh,0) rotate(${ startAngle }deg)`,
      ...(letterCss_extraSpringFrom || {}),
    },
    to: {
      transform: `translate3d(0,0,0) rotate(0deg)`,
      ...(letterCss_extraSpringTo || {}),
    },
    config: {
      ...DefaultPrimaryLetterSpringjsConfig,
      ...(primaryLetter_extraSpringConfig || {})
    },
    delay,
    onRest,
  });

  return <animated.span
    className={ letterClassName || ''}
    style={ Object.assign({ zIndex }, letterCss, springCss) }
  >{ letter }</animated.span>
}
