import { DefaultWranggleBackendApiBaseUrl } from "@wranggle/constants-iso";
import { wranggleBackendApiBaseUrlOverride, matchRouteVars, PlusPlusPlus } from "@wranggle/shared-browser-utils";


interface bestWranggleApiBaseUrlArgs {
  routeDef?: string;
}

/**
 * Identify backend api location.
 * Placeholder, needs work. Can override with session or local storage, process.env.
 * Also, has placeholders for localhost & spanggle.
 *
 * @param routeDef
 */
export function bestWranggleApiBaseUrl({ routeDef }: bestWranggleApiBaseUrlArgs): string {
  let res: string | undefined = wranggleBackendApiBaseUrlOverride();
  // if (!res) {
  //   const searchParams = new URLSearchParams(window.location.search);
  //   const qsOverride = searchParams.get('wranggleApi');
  //   if (qsOverride) {
  //     res = qsOverride;
  //   }
  // }
  if (!res && routeDef) {
    res = _placeholder_apiBaseUrlFromBackendCode(routeDef);
  }
  return res || DefaultWranggleBackendApiBaseUrl;
}

// const PublishedFrontendDomains = [
//   /\.spanggle\.com$/
// ];
const PlaceholderLocalDevHosts = [
  'localhost',
  'caddy-loadbalancer',
  'fanggle-platform',
];
function _placeholder_apiBaseUrlFromBackendCode(routeDef: string): string | undefined {
  const locHostname = window.location.hostname;
  let destHost: string;
  if (locHostname.endsWith('.spanggle.com')) {
    const { backendCode } = matchRouteVars({
      routeDef: routeDef,
      pathWithData: document.location.pathname || '',
    }) || {};
    if (!backendCode) {
      throw new PlusPlusPlus('InvalidPathDefinition', {
        reason: 'Expecting value for backendCode to have been embedded in path',
        routeDef,
        path: document.location.pathname,
      });
    }
    if (backendCode === '_') {
      destHost = 'wranggle.com';
    } else {
      destHost = `${ backendCode }.wranggle.com`;
    }
  } else {
    const localDev = PlaceholderLocalDevHosts.find(h => h === locHostname);
    destHost = localDev || 'error-unknown-local-dev-host!';
  }
  
  return `${ window.location.protocol }//${ destHost }`;
}


