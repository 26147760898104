import React, { CSSProperties } from "react";
import { DomToPuppyCssId_PageError, DomToPuppyCssId_PageFinished, DomToPuppyCssId_ReadyToStart } from "../page-constants";
import { useDebouncedLayoutRenderComplete } from "../state/use-debounced-render-complete";


const OffPageCss = {
  position: 'absolute',
  left: -9999,
  top: -9999,
  width: 1,
  height: 1,
  opacity: 1,
} as CSSProperties;

export interface PageReadyMarkerForPuppyServiceProps {
  /**
   * Pass in true once the target component is ready. Nothing added when false
   */
  isComponentReady: boolean;

  /**
   * Normally waits for React re-rendering to simmer down before adding page-ready signal element. Pass in true to skip this delay.
   */
  skipLayoutCompletionCheck?: boolean;

  /**
   * Option to set debounce interval used to await stabalized page render
   */
  debouncedRerenderIntervalMs?: number;
}
/**
 * Inserts an empty div with css ID that the puppy-service (Puppeteer lib) uses as a signal
 * It would be a bit faster to signal Puppeteer by emitting a custom event (vs use this built-in dom polling mechanism), maybe something for later.
 *
 * @constructor
 */
export function StabalizedPageReadyMarkerForPuppyService({ isComponentReady, skipLayoutCompletionCheck, debouncedRerenderIntervalMs }: PageReadyMarkerForPuppyServiceProps) {
  const isLayoutComplete = useDebouncedLayoutRenderComplete({ intervalMs: debouncedRerenderIntervalMs });

  const [ hasStabalizedOnce, setHasStabalizedOnce ] = React.useState(false);
  React.useEffect(() => {
    if (isLayoutComplete) {
      setHasStabalizedOnce(true);
    }
  }, [ isLayoutComplete ]);

  return <>
    {isComponentReady && (skipLayoutCompletionCheck || hasStabalizedOnce) &&
    <PageReadyMarkerForPuppyService />
    }
  </>;
}

/**
 * Components include this element when they render, signalling puppeteer that the page is ready to continue.
 *
 * note: puppeteer currently awaits this marker (using page.waitForSelector) after navigating.
 * It's probably based on polling. Sending a message more directly (with page.exposeFunction) might be faster but is more
 *  of a headache to use in React-based views.
 * Perhaps have it accept either.
 * @constructor
 */
export function PageReadyMarkerForPuppyService() {
  return <div
    id={DomToPuppyCssId_ReadyToStart}
    style={ OffPageCss }
  >&nbsp;</div>;
}

export function PageErrorMarkerForPuppyService() {
  return <h2
    id={DomToPuppyCssId_PageError}
    style={ OffPageCss }
  >Error In Page</h2>;
}

// todo: implement in puppy-service!
export function PageFinishedMarkerForPuppyService() {
  return <div
    id={DomToPuppyCssId_PageFinished}
    style={ OffPageCss }
  >&nbsp;</div>;
}