import React from "react";
import { useDebounce } from "react-use";

interface DebouncedRenderCompleteHookArgs {
  intervalMs?: number;
}
const DefaultDebouncedRenderCompleteHookArgs  = {
  intervalMs: 20,
} as DebouncedRenderCompleteHookArgs;

export function useDebouncedLayoutRenderComplete(args={} as DebouncedRenderCompleteHookArgs) {
  const { intervalMs } = Object.assign({}, DefaultDebouncedRenderCompleteHookArgs, args);

  const [ isLayoutSettled, setIsLayoutSettled ] = React.useState<boolean>(false);
  const [ layoutChangedAt, setLayoutChanging ] = React.useState(Date.now());

  useDebounce(
    () => {
      setIsLayoutSettled(true);
    },
    intervalMs,
    [ layoutChangedAt ]
  );

  const tickle = React.useCallback(() => {
    setIsLayoutSettled(false);
    setLayoutChanging(Date.now())
  }, []);

  React.useLayoutEffect(() => {
    tickle();
    return tickle;
  }, [ tickle ]);

  React.useEffect(() => {
    tickle();
    return tickle;
  }, [ tickle ]);

  return isLayoutSettled;
}