import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import { useEncodedQsPayload } from "../state/use-encoded-qs-payload";
import { ErrorBoundary } from "./layout/ErrorBoundary";
import { RouteNotFound } from "./layout/RouteNotFound";
import {
  PageReadyMarkerForPuppyService,
  StabalizedPageReadyMarkerForPuppyService
} from "./PageReadyMarkerForPuppyService";
import "./RenderForScreenshot.scss";

const Chartjs = React.lazy(() => import("./screenshot-features/Chartjs").then(m => ({ default: m.Chartjs })));


export enum ScreenshotFeatureCode {
  Chartjs = 'chartjs',
}


export function RenderForScreenshot() {
  const { screenshotCode } = useParams() as any;
  const screenshotQsData = useEncodedQsPayload('screenshotPageData');
  const mehGenericQsData = useEncodedQsPayload('payload');
  const containerCss = useEncodedQsPayload('containerCss');

  const screenshotPageData = screenshotQsData || mehGenericQsData || {};

  const [ isComponentReady, setIsComponentReady ] = React.useState<boolean>(false);


  const markReadyForScreenshot = () => setIsComponentReady(true);

  let content;
  let readyMarker;
  if (screenshotCode === ScreenshotFeatureCode.Chartjs) {
    content = <Chartjs
      chartjsPageData={screenshotPageData}
      markReadyForScreenshot={markReadyForScreenshot}
    />;

  } else {
    content = <RouteNotFound />;
    readyMarker = <PageReadyMarkerForPuppyService />;
  }
  readyMarker = readyMarker || <StabalizedPageReadyMarkerForPuppyService isComponentReady={isComponentReady} />;

  return (
    <div
      style={containerCss || {} }
      className="RenderForScreenshot"
    >
      <Suspense fallback={ <div>loading...</div>  }>
        <ErrorBoundary>
          { content }
          { readyMarker }
        </ErrorBoundary>
      </Suspense>
    </div>
  );
}
